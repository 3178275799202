<template>
  <div class="mb-10 flex flex-col justify-center items-center">
    <HeaderGlobal
      :step="$t('global_step_01_03')"
      :title="$t('global_header_registration_method')"
    />
    <div class="w-10/12">
      <InputImage
        @blurred="inputImg"
        :title="$t('global_add_profile_picture')"
      />
      <h1 class="text-xl font-proximaMedium">
        {{ $t("after_verification_title") }}
      </h1>
      <FormError :errors="errorPin" />
      <CompleteprofileForms
        v-if="roleComp == 1 || roleComp == 2"
        class="mt-8"
        :checkboxNews="checkboxNews"
        :email_address="email_address"
        :phone_number="phone_number"
        :provider="provider"
        :referral="referral"
        ref="child"
      />
      <div class="mt-2 text-xs font-proximaNormal flex items-start">
        <input
          class="mt-1"
          id="checkbox"
          type="checkbox"
          v-model="checkboxNews"
        />
        <p class="ml-2 mt-1">
          {{ $t("after_verification_checkbox_news") }}
        </p>
      </div>
      <SubmitButton
        :title="$t('after_verification_continue_button')"
        background="bg-primary"
        @click.native="submitForm"
      />
    </div>
  </div>
</template>

<script>
import HeaderGlobal from "../header/HeaderGlobal.vue";
import InputImage from "../forms/InputImage.vue";
import CompleteprofileForms from "./CompleteprofileForms.vue";
import DogBreedCompleteprofileForms from "./DogBreedCompleteprofileForms.vue";
import SubmitButton from "../buttons/SubmitButton.vue";
import FormError from "../warning/FormError.vue";
import { getEmailcheckRegistration } from "../../services/emailService";

export default {
  components: {
    FormError,
    HeaderGlobal,
    InputImage,
    CompleteprofileForms,
    SubmitButton,
  },
  data() {
    return {
      pinNumber: "",
      errorPin: [],
      image: null,
      checkboxNews: false,
      email_address: "",
      phone_number: "",
      provider: "",
      role: "",
      type: "",
      referral: "",
    };
  },
  computed: {
    roleComp() {
      return localStorage.getItem("role");
    },
  },
  async created() {
    if (localStorage.getItem("role") == 2) {
      this.email_address = this.$route.params.email_address;
      this.phone_number = this.$route.params.phone_number.replace(/\s+/g, '');
      this.provider = this.$route.params.provider;
      this.role = localStorage.getItem("role");
      this.referral = localStorage.getItem("referral");
      this.ruleID = localStorage.getItem("ruleID");
      localStorage.setItem("email", this.email_address);
    } else {
      await getEmailcheckRegistration(this.$route.params["code"])
        .then((response) => {
          console.log("kkkkkk", response.data);
          this.phone_number = response.data.phone_number;
          this.email_address = response.data.email_address;
          this.role = response.data.role;
          this.provider = response.data.provider;
          this.type = response.data.servicePracticeType;
          this.referral = response.data.referral;
          this.ruleID = response.data.ruleID;
          localStorage.setItem("role", this.role);
          localStorage.setItem("email", this.email_address);

          /* if (this.role == 1) {
          this.$router.push({
            name: "CompleteYourProfile",
            params: {
              email_address: this.email_address,
              phone_number: this.phone_number,
              provider: this.provider,
            },
          });
        } */
          //shelter
          if (this.role == 6) {
            this.$router.push({
              name: "ShelterCompleteProfile",
              params: {
                email_address: this.email_address,
                phone_number: this.phone_number,
                provider: this.provider,
              },
            });
          }
          //dogbreed
          if (this.role == 3) {
            this.$router.push({
              name: "DogBreedCompleteProfile",
              params: {
                email_address: this.email_address,
                phone_number: this.phone_number,
                provider: this.provider,
                referral: this.referral,
              },
            });
          }
          if (this.role == 4) {
            this.$router.push({
              name: "ServiceproviderCompleteProfile",
              params: {
                email_address: this.email_address,
                phone_number: this.phone_number,
                provider: this.provider,
                serviceType: this.type,
              },
            });
          }
          if (this.role == 5) {
            this.$router.push({
              name: "VeterinarianCompleteProfile",
              params: {
                email_address: this.email_address,
                phone_number: this.phone_number,
                provider: this.provider,
                practiceType: this.type,
              },
            });
          }
        })
        .catch((error) => {
          if (error.response && error.response.status == 422) {
            this.errorPin.push("email already verified");
          } else {
            this.errorPin.push(
              "Your session has expired. Please go back to register again."
            );
          }
        });
    }
  },
  methods: {
    inputImg(val) {
      this.image = val;
    },

    submitForm() {
      this.$refs.child.submitForm(
        this.image,
        this.checkboxNews,
        this.email_address,
        this.phone_number,
        this.provider,
        this.role,
        this.type,
        this.referral,
        this.ruleID
      );
    },
  },
};
</script>

<style></style>
