<template>
  <div>
    <FormError :errors="errors.save" />
    <div class="flex justify-between">
      <InputForm
        :label="$t('global_firstname')"
        v-model="account.firstName"
        classAdd="w-full mr-1 flex flex-col justify-start items-start"
        :error="errors.firstName"
      />
      <InputForm
        :label="$t('global_lastname')"
        v-model="account.lastName"
        classAdd="w-full mr-1 flex flex-col justify-start items-start"
        :error="errors.lastName"
      />
    </div>

    <!--  <InputForm
      :label="$t('global_street_address')"
      v-model="account.streetAddress"
      classAdd="mt-6 flex flex-col justify-start items-start"
      :error="errors.streetAddress"
    /> -->

    <div class="mt-5 flex justify-between">
      <div class="w-full mr-1 flex flex-col justify-start items-start">
        <label class="text-sm font-proximaMedium" for="country">{{
          $t("global_country")
        }}</label>
        <select
          class="w-full bg-white text-mediumGrey rounded-sm px-2 py-3 my-1 text-sm"
          v-model="account.country"
          @change="inputSelectCountry()"
        >
          <option value="" disabled selected>
            {{ $t("global_select_options") }}
          </option>
          <option v-for="item in countriesList" :value="item.id" :key="item.id">
            {{ item.name }}
          </option>
        </select>
        <FormError :errors="errors.country" />
      </div>
      <!--  <div class="w-full ml-1 flex flex-col justify-start items-start">
        <label class="text-sm font-proximaMedium" for="state">{{
          $t("global_state")
        }}</label>
        <select
          class="w-full bg-white text-mediumGrey rounded-sm px-2 py-3 my-1 text-sm"
          v-model="account.state"
        >
          <option value="" disabled selected>
            {{ $t("global_select_options") }}
          </option>
          <option v-for="item in statesList" :value="item.id" :key="item.id">
            {{ item.name }}
          </option>
        </select>
        <FormError :errors="errors.state" />
      </div> -->
    </div>
    <div class="mt-5 flex justify-between">
      <!-- <InputForm
        :label="$t('global_city')"
        v-model="account.city"
        classAdd="w-full mr-1 flex flex-col justify-start items-start"
        :error="errors.city"
      /> -->
      <InputForm
        :label="$t('global_postal_code')"
        v-model="account.postalCode"
        classAdd="w-full ml-1 flex flex-col justify-start items-start"
        :error="errors.postalCode"
      />
    </div>
  </div>
</template>

<script>
import FormError from "../warning/FormError.vue";
import InputForm from "../forms/InputForm.vue";
import { getCountries } from "../../services/countriesServices";
import { getStates } from "../../services/stateServices";
import { mapActions } from "vuex";

export default {
  name: "signInAfterLogin",
  props: {
    email_address: String,
    checkboxNews: Boolean,
    phone_number: String,
    provider: String,
  },

  

  components: {
    FormError,
    InputForm,
  },
  data() {
    return {
      account: {
        firstName: "",
        lastName: "",
        streetAddress: "",
        country: "",
        state: "",
        city: "",
        postalCode: "",
        image: null,
        email_address: this.$route.params.email_address,
        phone_number: this.$route.params.phone_number,
        provider: this.$route.params.provider,
        coordinates: { lng: 3.05997, lat: 36.7762 },
        checkboxNews: this.checkboxNews ? 1 : 0,
      },
      errors: {},
      countriesList: [],
      statesList: [],
    };
  },
  created() {
    this.$getLocation({}).then((coordinates) => {
      this.account.coordinates = coordinates;
    });
  },
  async mounted() {
    const a = await getCountries();
    this.countriesList = a.data;
  },
  async created() {
    const a = await getCountries();
    this.countriesList = a.data;
    console.log(this.countriesList);
    if (this.account.country) {
      const c = await getStates(this.account.country);
      this.statesList = c.data;
      console.log(this.statesList);
    }
  },
  methods: {
    ...mapActions({
      signIn: "auth/signIn",
      saveUserLogin: "auth/saveUserLogin",
    }),

    async inputSelectCountry() {
      const a = await getStates(this.account.country);
      this.statesList = a.data;
    },

    validate() {
      const errors = {};

      if (this.account.firstName.trim() === "")
        errors.firstName = "FirstName  is required";
      if (this.account.lastName.trim() === "")
        errors.lastName = "LastName  is required";
      // if (this.account.streetAddress.trim() === "")
      //   errors.streetAddress = "Address  is required";
      if (this.account.country === "")
        errors.country = this.$t("global_error_country");
      //if (this.account.state === "") errors.state = "State  is required";
      //if (this.account.city.trim() === "") errors.city = "City  is required";
      if (this.account.postalCode.trim() === "")
        errors.postalCode = "Postal Code  is required";

      return Object.keys(errors).length === 0 ? null : errors;
    },

    async submitForm(
      image,
      checkboxNews,
      email_address,
      phone_number,
      provider,
      role,
      type,
      referral,
      ruleID
    ) {
      const errors = this.validate();
      this.errors = errors || {};
      if (errors) return;

      this.account.image = image;
      this.account.checkboxNews = checkboxNews ? 1 : 0;
      this.account.email_address = email_address;
      this.account.phone_number = phone_number;
      this.account.provider = provider;
      this.account.role = role;
      this.account.referral = referral;
      this.account.ruleID = ruleID;

      console.log(this.account);

      this.saveUserLogin(this.account);
    },
  },
};
</script>

<style></style>
