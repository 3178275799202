<template>
  <div>
    <FormError :errors="errors.save" />
    <div class="flex justify-between">
      <InputForm
        :label="$t('global_firstname')"
        v-model="account.firstName"
        classAdd="w-full mr-1 flex flex-col justify-start items-start"
        :error="errors.firstName"
      />
      <InputForm
        :label="$t('global_lastname')"
        v-model="account.lastName"
        classAdd="w-full mr-1 flex flex-col justify-start items-start"
        :error="errors.lastName"
      />
    </div>
    <InputForm
      :label="$t('global_street_address')"
      v-model="account.kennelAddress"
      classAdd="mt-6 flex flex-col justify-start items-start"
      :error="errors.kennelAddress"
    />

    <div class="mt-5 flex justify-between">
      <div class="w-full mr-1 flex flex-col justify-start items-start">
        <label class="text-sm font-proximaMedium" for="country">{{
          $t("global_country")
        }}</label>
        <select
          class="w-full bg-white text-mediumGrey rounded-sm px-2 py-3 my-1 text-sm"
          v-model="account.country"
          @change="inputSelectCountry()"
        >
          <option value="" disabled selected>
            {{ $t("global_select_options") }}
          </option>
          <option v-for="item in countriesList" :value="item.id" :key="item.id">
            {{ item.name }}
          </option>
        </select>
        <FormError :errors="errors.country" />
      </div>
      <div class="w-full ml-1 flex flex-col justify-start items-start">
        <label class="text-sm font-proximaMedium" for="state">{{
          $t("global_state")
        }}</label>
        <select
          class="w-full bg-white text-mediumGrey rounded-sm px-2 py-3 my-1 text-sm"
          v-model="account.state"
        >
          <option value="" disabled selected>
            {{ $t("global_select_options") }}
          </option>
          <option v-for="item in statesList" :value="item.id" :key="item.id">
            {{ item.name }}
          </option>
        </select>
        <FormError :errors="errors.state" />
      </div>
    </div>
    <div class="mt-5 flex justify-between">
      <InputForm
        :label="$t('global_city')"
        v-model="account.city"
        classAdd="w-full mr-1 flex flex-col justify-start items-start"
        :error="errors.city"
      />
      <InputForm
        :label="$t('global_postal_code')"
        v-model="account.postalCode"
        classAdd="w-full ml-1 flex flex-col justify-start items-start"
        :error="errors.postalCode"
      />
    </div>
    <div class="mt-5 flex justify-between">
      <div class="w-full mr-1 flex flex-col justify-start items-start">
        <label class="text-sm font-proximaMedium" for="kennelClub">{{
          $t("after_verification_kannel")
        }}</label>
        <select
          class="w-full bg-white text-mediumGrey rounded-sm px-2 py-3 my-1 text-sm"
          v-model="account.kennelClub"
        >
          <option value="" disabled selected>
            {{ $t("global_select_options") }}
          </option>
          <option
            v-for="item in kennelClubsList"
            :value="item.id"
            :key="item.id"
          >
            {{ item.name }}
          </option>
        </select>
        <FormError :errors="errors.kennelClub" />
      </div>
      <div class="w-full ml-1 flex flex-col justify-start items-start">
        <label class="text-sm font-proximaMedium" for="kennelFoundationDate">{{
          $t("global_registration_date") + "*"
        }}</label>
        <input
          type="date"
          id="kennelFoundationDate"
          v-model="account.kennelFoundationDate"
          class="w-full rounded-sm px-3 py-3 my-1 text-sm placeholder-darkGrey"
        />
        <FormError :errors="errors.kennelFoundationDate" />
      </div>
    </div>

    <InputForm
      :label="$t('after_verification_kannel_registration_name')"
      v-model="account.kennelName"
      classAdd="mt-6 flex flex-col justify-start items-start"
      :error="errors.kennelName"
    />
    <InputForm
      :label="$t('after_verification_kannel_registration_number')"
      v-model="account.kennelRegistrationNo"
      classAdd="mt-6 flex flex-col justify-start items-start"
      :error="errors.kennelRegistrationNo"
    />
  </div>
</template>

<script>
import FormError from "../warning/FormError.vue";
import InputForm from "../forms/InputForm.vue";

import { getCountries } from "../../services/countriesServices";
import { getStates } from "../../services/stateServices";
import { getKennelClubs } from "../../services/dogBreedersService";

import { mapActions } from "vuex";

export default {
  name: "saveDogBreederLogin",
  props: {
    email_address: String,
    checkboxNews: Boolean,
    phone_number: String,
    provider: String,
    referral: String,
  },
  components: {
    FormError,
    InputForm,
  },
  data() {
    return {
      account: {
        firstName: "",
        lastName: "",
        postalCode: "",
        kennelAddress: "",
        kennelClub: "",
        kennelName: "",
        kennelFoundationDate: "",
        kennelRegistrationNo: "",
        postalCode: "",
        country: "",
        state: "",
        city: "",
        postalCode: "",
        image: null,
        email_address: this.email_address,
        phone_number: this.phone_number,
        provider:this.provider,
        referral:this.referral,
        coordinates: { lng: 3.05997, lat: 36.7762 },
        checkboxNews: this.checkboxNews ? 1 : 0,
      },
      errors: {},
      countriesList: [],
      statesList: [],
      kennelClubsList: [],
    };
  },
  async created() {
    this.$getLocation({}).then((coordinates) => {
      this.account.coordinates = coordinates;
    });

    if (!this.email_address || !this.phone_number || !this.provider) {
      this.$router.push({ name: "Join" });
    }
    const a = await getCountries();
    this.countriesList = a.data;

    const b = await getKennelClubs();
    this.kennelClubsList = b.data;
  },

  methods: {
    ...mapActions({
      signIn: "auth/signIn",
      saveDogBreederLogin: "auth/saveDogBreederLogin",
    }),

    async inputSelectCountry() {
      const a = await getStates(this.account.country);
      this.statesList = a.data;
    },

    validate() {
      const errors = {};

      if (this.account.firstName.trim() === "")
        errors.firstName = "FirstName  is required";
      if (this.account.lastName.trim() === "")
        errors.lastName = "LastName  is required";
      if (this.account.kennelAddress.trim() === "")
        errors.kennelAddress = "Address  is required";
      if (this.account.country === "")
        errors.country = this.$t("global_error_country");
      if (this.account.state === "") errors.state = "State  is required";
      if (this.account.city.trim() === "") errors.city = "City  is required";
      if (this.account.postalCode.trim() === "")
        errors.postalCode = "Postal Code  is required";
      if (this.account.kennelClub === "")
        errors.kennelClub = "Kennel Club is required";
      if (this.account.kennelName.trim() === "")
        errors.kennelName = "Kennel Registration is required";
      if (this.account.kennelRegistrationNo.trim() === "")
        errors.kennelRegistrationNo = this.$t(
          "after_verification_error_kannel_registration_number"
        );

      return Object.keys(errors).length === 0 ? null : errors;
    },

    async submitForm(image) {
      const errors = this.validate();
      this.errors = errors || {};
      if (errors) return;

      this.account.image = image;

      this.saveDogBreederLogin(this.account);
    },
  },
};
</script>

<style></style>
